import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TextJumbotron from "../../components/util/textJumbotron";
import { Container, Row, Col, CardDeck } from "react-bootstrap";
import ClassCard from "../../components/home/classCard";

export default function PreschoolToK9Page({ data }) {
  return (
    <BaseLayout pageTitle="Tutoring: Preschool to K9">
      <TextJumbotron heading="Tutoring: Preschool to K9" />
      <Container>
        <Row>
          <Col>
            {" "}
            <ul>
              <li>Affordable</li>
              <li>School - aligned curriculum</li>
              <li>Creative Problem Solving</li>
              <li>Students attend a designated session once a week.</li>
              <li>
                Student can attend once, twice or three times a week depending
                on the program they have signed up for.
              </li>
              <li>
                Teachers design a custom program based on the results of initial
                evaluation.
              </li>
              <li>
                Every child’s learning plan is unique. We mix the best in
                teaching and technology to ensure your child gets just what he
                or she needs.
              </li>
              <li>
                Students never have to wait to receive attention, and will
                usually receive it “instantly”, due to low student-to-teacher
                ratios
              </li>
              <li>Daily HW worksheet provided (15 mins).</li>
              <li>In-person and online option</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pb-3">
            <h2 className="text-center">Subjects Offered</h2>
          </Col>
          <Col xs={12}>
            <CardDeck>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="Math"
              >
                <p>
                  Our math tutoring programs make math easier to understand with
                  engaging, fun math lessons that help students gain a better
                  understanding of the material.
                </p>
              </ClassCard>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="English"
              >
                <p>
                  English programs are designed to help students build more
                  effective reading, writing, grammar and comprehension skills
                  that will help them achieve better English grades.
                </p>
              </ClassCard>
              <ClassCard
                btnText="Register"
                btnLink="/registration"
                title="Science"
              >
                <p>
                  Tutors provide a personalized approach that helps your child
                  understand science in a way that makes sense to him or her.
                </p>
              </ClassCard>
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
